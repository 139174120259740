<template>
  <div class="flex flex-col items-center py-4">
    <policies-and-templates-form
      :show-checklist-config="true"
      :show-print-config="true"
      :show-order-status-config="true" />
  </div>
</template>
<script>
export default {
  name: 'PoliciesAndTemplates',
  components: {
    PoliciesAndTemplatesForm: () => import('@/components/forms/PoliciesAndTemplatesForm'),
  },
}
</script>
